

export const getCurrencySymbol = (name: string) => {

    if(name == 'gbp') return '£';
    if(name == 'eur') return '€';
    if(name == 'usd') return '$';
    
    return '£';

}