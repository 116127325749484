const Exhibitor = ({ exhibitor }: any) => (
    <div className="flex items-center p-4 bg-white shadow-md rounded-lg mb-4 cursor-pointer hover:shadow-lg transition-shadow">
        <div className="flex items-center space-x-4">
            <img
                className="w-16 h-16 object-contain"
                src={`${process.env.REACT_APP_BACKEND_URL}/${exhibitor.company.logo}`}
                alt={`${exhibitor.name} Logo`}
            />
            <div>
                <div className="text-sm font-semibold">{exhibitor.company.name}</div>
                <div className="text-sm text-gray-500">{exhibitor.category}</div>
            </div>
        </div>
        <div className="ml-auto text-lg font-bold text-gray-700">
            {exhibitor.booth}
        </div>
    </div>
);

const ExhibitorList = ({ exhibitors, onHoverItem, onClickItem }: any) => {
    return (
        <div
            className="space-y-4 h-full overflow-y-auto"
            style={{ height: 'calc(100vh - 250px)' }}
        >
            {
                exhibitors.map((exhibitor: any) => (
                    <div
                        key={exhibitor.id}
                        onClick={(e: any) => onClickItem(exhibitor)}
                        onMouseOver={(e: any) => onHoverItem(exhibitor)}
                        onMouseLeave={(e: any) => onHoverItem({})}
                    >
                        <Exhibitor key={exhibitor.id} exhibitor={exhibitor} />
                    </div>
                ))
            }
        </div >
    );
};

export default ExhibitorList;
